import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { catchFunction, getConfig } from 'actions/utils';

import axiosInstance from '../../../actions/axiosInstance';

export const initialState = {
  formulatorFolders: undefined,
  error: undefined,
  isLoading: false,
};
export const updateTrashFormulatorFolders = createAsyncThunk(
  'update_formulator_folders',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/rest_api/trash/formulator/folders`,
        data,
        getConfig(),
      );

      return response.data;
    } catch (error) {
      catchFunction({
        error,
        dispatch,
        refreshFunction: updateTrashFormulatorFolders(data),
      });

      return rejectWithValue(error.message);
    }
  },
);

export const updateFormulatorFoldersTrash = createSlice({
  name: 'update_formulator_folders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateTrashFormulatorFolders.pending, (state) => {
        state.isLoading = true;
        state.formulatorFolders = undefined;
        state.error = undefined;
      })
      .addCase(updateTrashFormulatorFolders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formulatorFolders = action.payload;
      })
      .addCase(updateTrashFormulatorFolders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default updateFormulatorFoldersTrash.reducer;
